import {
  paymentWays,
  ItemTransactionPaymentType,
  invoiceValidationTypes,
} from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      // lookups entites
      paymentWays: paymentWays,
      itemTransactionTypes: ItemTransactionPaymentType,
      invoiceValidationTypes,
      // lookup object
      lookups: {
        suppliers: [],
        agents: [],
        taxLinks: [],
        stores: [],
        taxes: [],
        safes: [],
      },
      // invoice Model
      selectedItem: {
        code: '',
        isOffline: false,
        transactionSource: 'web',
        transactionDate: '',
        notes: '',
        isPosted: false,
        postedVoucherId: null,
        transactionType: 'purch',
        transactionChannel: 'normal',
        sourceTransactionId: null,
        paymentType: null,
        priceListId: null,
        dealerId: null,
        onTheFlyDealerName: '',
        agentId: null,
        storeId: null,
        driverId: null,
        carId: null,
        projectId: null,
        total: 0,
        discountPercentage: 0,
        discountValue: 0,
        netBeforeTaxes: 0,
        totalTaxes: 0,
        net: 0,
        isComplete: false,
        lines: [],
        taxes: [],
        payments: [],
        costCenters: [],
        expenses: [],
      },
    };
  },
  computed: {
    status() {
      if (this.selectedItem.isComplete) return this.$t('complete');
      if (this.selectedItem.isPosted) return this.$t('posted');
      if (this.selectedItem.lines.some((i) => i.consumedQuantity)) return this.$t('usedInAnother');
      return null;
    },
    isClosed() {
      // return !!this.status;
      return (
        this.selectedItem.isPosted || this.selectedItem.isComplete
        || this.selectedItem.lines.some((i) => i.consumedQuantity === i.originalQuantity)
      );
    },
    hasSource() {
      return this.selectedItem.sourceTransactionId > 0;
    },
    isEditable() {
      return this.selectedItem.id || this.selectedItem.sourceTransactionId;
    },
    companyTax() {
      return [
        {
          taxTypeArabicName: this.$t('companyTax'),
          taxTypeEnglishName: this.$t('companyTax'),
          taxTypeId: '',
          taxPercentage: this.company.taxPercentage,
          taxValue: 0,
        },
      ];
    },
    isLineBaseDiscount() {
      return this.currentBranch.discountLevel === 'items';
    },
    tableColumns() {
      return [
        {
          key: 'paymentMethod',
          label: this.$t('paymentType'),
          isLocale: true,
          sortable: false,
        },
        {
          key: this.isRight
            ? 'otherPaymentMethodArabicName'
            : 'otherPaymentMethodEnglishName',
          label: this.$t('safe'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.safeId) return this.isRight ? item.safeArabicName : item.safeEnglishName;
            return this.isRight
              ? item.otherPaymentMethodArabicName
              : item.otherPaymentMethodEnglishName;
          },
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: false,
          footer: () => this.fraction(this.remaindered),
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    const { query } = this.$route;
    Object.keys(query)
      .filter((key) => this.selectedItem[key] !== undefined)
      .forEach((key) => {
        this.selectedItem[key] = query[key];
      });
  },
  methods: {
    computeTotalDiscount() {
      // if (!this.isLineBaseDiscount && this.selectedItem.discountPercentage) {
      //   this.selectedItem.discountValue = (parseFloat(this.total) * this.selectedItem.discountPercentage) / 100;
      // }
      // compute discount for item based
      if (this.isLineBaseDiscount) {
        let itemTotal = 0;
        let itemDiscount = 0;
        this.selectedItem.lines.forEach((item) => {
          itemDiscount += parseFloat(item.discountValue);
          itemTotal += parseFloat(item.total);
        });
        this.selectedItem.total = itemTotal;
        this.selectedItem.discountValue = itemDiscount;
        this.selectedItem.discountPercentage = this.fraction(
          (itemDiscount / itemTotal) * 100
        );
      }
    },
    computeTotalTaxes() {
      if (this.currentBranch.taxPolicy === 'netBased') {
        this.companyTax.forEach((tax) => {
          tax.value = this.selectedItem.netBeforeTaxes * (15 / 100);
          this.selectedItem.totalTaxes += Number(tax.value);
        });
        this.selectedItem.net = this.selectedItem.netBeforeTaxes + this.selectedItem.totalTaxes;
      } else if (this.currentBranch.taxPolicy === 'priceIncluded') {
        // get taxes from item
        this.companyTax.forEach((tax) => {
          tax.value = this.selectedItem.lines.reduce((taxTotal, item) => {
            // if (!item.isTaxable) return taxTotal; // onley in item based
            // taxTotal += (item.net * (parseFloat(tax.taxPercentage) / 100));
            // var itemDiscount = item.net * (parseFloat(this.selectedItem.discountPercentage) / 100);
            const price = item.net / (1 + (parseFloat(tax.taxPercentage) / 100));
            taxTotal += price * (parseFloat(tax.taxPercentage) / 100);
            return taxTotal;
          }, 0);
          this.selectedItem.totalTaxes += parseFloat(Number(tax.value));
        });
        this.selectedItem.netBeforeTaxes -= this.selectedItem.totalTaxes;
      } else if (this.currentBranch.taxPolicy === 'itemBased') {
        this.companyTax.forEach((tax) => {
          tax.value = this.selectedItem.lines.reduce((taxTotal, item) => {
            if (!item.isTaxable) return taxTotal;
            // var itemDiscount = item.net * (parseFloat(this.selectedItem.discountPercentage) / 100);
            // var itemNet = item.net - parseFloat(this.selectedItem.discountPercentage);
            taxTotal += item.net * (parseFloat(tax.taxPercentage) / 100);
            return taxTotal;
          }, 0);
          this.selectedItem.totalTaxes += parseFloat(Number(tax.value));
        });
        this.selectedItem.net = this.selectedItem.netBeforeTaxes + this.selectedItem.totalTaxes;
      }
    },
    getSelecteItemObj() {
      this.selectedItem = {
        code: '',
        isOffline: false,
        transactionSource: 'web',
        transactionDate: '',
        notes: '',
        isPosted: false,
        postedVoucherId: null,
        transactionType: 'purch',
        transactionChannel: 'normal',
        sourceTransactionId: null,
        paymentType: null,
        priceListId: null,
        dealerId: null,
        onTheFlyDealerName: '',
        agentId: null,
        storeId: null,
        driverId: null,
        carId: null,
        projectId: null,
        total: 0,
        discountPercentage: 0,
        discountValue: 0,
        netBeforeTaxes: 0,
        totalTaxes: 0,
        net: 0,
        isComplete: false,
        lines: [],
        taxes: [],
        payments: [],
        costCenters: [],
        expenses: [],
      };
      this.setupDefualts();
      this.getAppliedTaxes();
    },
    getModel() {
      const model = JSON.parse(JSON.stringify(this.selectedItem));
      // remove unneeded properities from model items
      model.lines = model.lines.map((item) => {
        return {
          transactionId: model.id > 0 ? item.transactionId : 0,
          lineSerial: item.lineSerial,
          storeId: item.storeId,
          itemId: item.itemId,
          unitId: item.unitId,
          unitConversionRate: item.unitConversionRate,
          quantity: item.quantity,
          price: item.price,
          total: item.total,
          serial: item.serial || null,
          carStatus: item.carStatus || null,
          carColorId: item.carColorId || null,
          attachmentDetails: item.attachmentDetails || [],
          costPrice: item.costPrice,
          discountPercentage: item.discountPercentage,
          discountValue: item.discountValue,
          net: item.net,
          notes: item.notes,
        };
      });

      // remove unneeded properities from model payments
      model.payments = model.payments.map((item) => {
        return {
          transactionId: item.transactionId || 0,
          lineSerial: item.lineSerial,
          value: item.value,
          paymentMethod: item.paymentMethod,
          safeId: item.safeId,
          paymentDate: item.paymentDate,
          otherPaymentMethodId: item.otherPaymentMethodId,
          referenceNumber: item.referenceNumber,
        };
      });

      model.taxes = model.taxes.map((item) => {
        return {
          transactionId: model.id || 0,
          taxTypeId: item.taxTypeId,
          taxPercentage: item.taxPercentage,
          taxValue: item.taxValue,
        };
      });
      return model;
    },
    getAppliedTaxes() {
      const supplier = this.lookups.suppliers.find(x => x.id === this.selectedItem.dealerId);
      // compute applied taxes
      if (this.id > 0) return;
      if (this.currentBranch.taxPolicy === 'netBased') {
        var taxVal = 0
        this.selectedItem.taxes = this.lookups.taxLinks.map((tax) => {
          if (supplier && !supplier.isTaxable) {
            taxVal = 0
          } else {
            taxVal = tax.taxType.taxPercentage
          }
          return {
            taxTypeArabicName: tax.taxType.arabicName,
            taxTypeEnglishName: tax.taxType.englishName,
            taxTypeId: tax.taxType.id,
            taxPercentage: taxVal,
            taxValue: 0,
          };
        });
      } else this.selectedItem.taxes = [];
    },
    preparePaymentModel() {
      this.paymentMethod.lineSerial = this.selectedItem.payments.length + 1;
      this.paymentMethod.safeArabicName = this.safeTransactions.safeArabicName;
      this.paymentMethod.safeEnglishName = this.safeTransactions.safeEnglishName;
      // this.paymentMethod.safeId = null;
      this.paymentMethod.otherPaymentMethodId = this.paymentMethod.otherPaymentMethodId || null;
      if (this.payemntType === 'Safes') {
        this.paymentMethod.paymentMethod = 'safe';
        this.paymentMethod.safeId = this.safeTransactions.safeId || null;
      } else {
        this.paymentMethod.paymentMethod = 'other';
      }
    },
    saveAsDraft() {
      if (this.selectedItem.dealerId && this.selectedItem.storeId && this.selectedItem.lines.length > 0) {
        // Add
      }
    },
    setupDefualts() {
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.paymentType = 'credit';
      this.selectedItem.transactionTime = this.time;
      this.selectedItem.storeId = this.currentBranch.defaultStoreId || null;
      if (this.$route.query.dealerId) {
        this.selectedItem.dealerId = Number(this.$route.query.dealerId)
      } else {
      this.selectedItem.dealerId = this.selectedItem.dealerId || null;
      }
      this.changeCustomer(this.lookups.suppliers.find((item) => item.id === this.selectedItem.dealerId) || {});
      this.selectedItem.discountPercentage = this.customer.discount;
      this.selectedItem.priceListId = this.currentBranch.defaultPriceListId || null;
    },
    beforeSaveValidation() {
      if (this.selectedItem.discountPercentage > this.lookups.currentUser.maxAllowedDiscount
          && this.customer.discount > this.selectedItem.discountPercentage) {
        // alert here
        this.doneAlert({ text: this.$t('DiscountValueIsMoreThanLimit'), type: 'error' });
        return false;
      }
      return true;
    },
    beforeSaveInvTypeValidation() {
      if (this.selectedItem.paymentType === 'cash' && this.customer.cashPurchase === false) {
        // alert here
        this.doneAlert({ text: this.$t('notAllowPaymentTypeForSupplier'), type: 'error' });
        return false;
      }
      if (this.selectedItem.paymentType === 'credit' && this.customer.creditPurchase === false) {
        // alert here
        this.doneAlert({ text: this.$t('notAllowPaymentTypeForSupplier'), type: 'error' });
        return false;
      }
      return true;
    },
    beforeSaveValidationDealer() {
      if (!this.selectedItem.dealerId) {
        // alert here
        this.doneAlert({ text: this.$t('youShoudSelectSupplier'), type: 'error' });
        return false;
      }
      return true;
    },
    updateViewModel(taxType = 'purch') {
      if (this.transactionType !== 'additionOrder' && this.transactionType !== 'manufacturing') {
        this.transType = this.transactionType;
      } else {
        this.transType = '';
      }
      this.get({
        url: `Aggregates/purchaseViewModel?userId=${this.profile.userId}&isClosed=false&transactionType=${taxType}&dealerType=${this.transType}`,
      }).then((data) => {
        this.lookups = data;
        this.lookups.suppliers.forEach(element => {
          element.arabicName = `${element.code} - ${element.arabicName}`;
          element.englishName = `${element.code} - ${element.englishName}`;
        });
        this.handelLookups();
        if (!this.id && !this.selectedItem.sourceTransactionId) this.setupDefualts();
      })
    },
    handelLookups() {
      if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
        const branchStores = this.currentBranch.stores.map(
          (item) => item.storeId
        );
        this.lookups.stores = this.lookups.stores.filter(
          (item) => branchStores.indexOf(item.id) > -1
        );
      } else {
        this.lookups.stores = [];
      }
      /* eslint-disable prefer-destructuring */
      this.safeTransactions = this.lookups.userSafes ? this.lookups.userSafes.data[0] : {};
      // this.getAppliedTaxes();
    },
  },
};
