<template>
  <b-card>
    <g-form
      ref="form"
      @submit="save"
    >
      <b-row class="my-2">
        <b-col
          md="12"
          class="border-end"
        >
          <div class="row">
            <b-col
              v-if="id > 0"
              md="4"
            >
              <g-field
                :value.sync="selectedItem.code"
                label-text="code"
                disabled
              />
            </b-col>
            <b-col
              v-if="currentBranch.enableEditingTransactionDates"
              md="4"
            >
              <g-picker
                :value.sync="selectedItem.transactionDate"
                label-text="transactionDate"
                name="date"
              />
            </b-col>
            <b-col
              v-if="!currentBranch.enableEditingTransactionDates"
              md="4"
            >
              <g-field
                :value="getDate(selectedItem.transactionDate)"
                label-text="transactionDate"
                disabled
                name="date"
              />
            </b-col>
            <b-col md="4">
              <g-field
                :value="selectedItem.transactionTime"
                label-text="transactionTime"
                name="transactionTime"
                readonly
              />
            </b-col>
            <b-col md="4">
              <g-field
                :options="lookups.stores"
                label-text="store"
                field="select"
                rules="required"
                name="StoreId"
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="
                  hasSource ||
                    isClosed ||
                    (selectedItem.lines && selectedItem.lines.length > 0)
                "
                @change="
                  (v) => {
                    validateOpening(v.id);
                  }
                "
              />
            </b-col>
            <!-- <b-col
              v-if="selectedItem.sourceTransactionId"
              md="3"
            >
              <g-field
                label-text="supplier"
                field="select"
                rules="required"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="hasSource || isClosed"
                :value.sync="selectedItem.dealerId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.suppliers"
                @change="changeCustomer"
              />
            </b-col>
            <b-col
              v-if="selectedItem.sourceTransactionId"
              md="3"
            >
              <g-field
                label-text="agent"
                :value.sync="selectedItem.agentId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.agents"
                field="select"
                name="agentId"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="hasSource || isClosed"
              />
            </b-col> -->
            <b-col md="4">
              <g-field
                label="arabicName"
                :dir="isRight ? 'rtl' : 'ltr'"
                rules="required"
                label-text="supplier"
                field="select"
                name="supplierId"
                :options="suppliers"
                :value.sync="selectedItem.supplierId"
              />
            </b-col>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!selectedItem.sourceTransactionId">
        <!-- <b-col md="3">
          <b-form-group>
            <g-field
              label-text="exchangeMethods"
              :value.sync="selectedItem.exchangeDestinationType"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="exchangeDestinationType"
              rules="required"
              :options="exchangeMethods"
              label="arabicName"
              @change="
                (v) => {
                  methodTypeName = isRight ? v.arabicName : v.englishName;
                  changedTypes(v.id);
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedItem.exchangeDestinationType"
          md="3"
        >
          <b-form-group>
            <g-field
              :label-text="$t(methodTypeName)"
              :value.sync="selectedItem.selectedDestinationId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              rules="required"
              name="selectedDestinationId"
              :options="changedData"
              label="arabicName"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          v-if="id"
          md="3"
        >
          <g-field
            label-text="createdBy"
            :value="selectedItem.createdUserName"
            placeholder="createdBy"
            name="createdBy"
            readonly
          />
        </b-col>
        <b-col
          v-if="id && selectedItem.updatedUserName"
          md="3"
        >
          <g-field
            label-text="updatedBy"
            :value="selectedItem.updatedUserName"
            placeholder="updatedBy"
            name="updatedBy"
            readonly
          />
        </b-col>
        <!-- <b-col
          v-if="checkIsOpening"
          md="4"
        >
          <b-form-group :label="$t('isOpeningOrder')">
            <b-form-checkbox
              v-model="selectedItem.isOpening"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <items
            :exchange-destination-type="selectedItem.exchangeDestinationType"
            :store-id="selectedItem.storeId"
            :total-expenses="totalExpenses"
            :invoice-total="this.total"
            :items.sync="selectedItem.lines"
            :has-source="selectedItem.sourceTransactionId > 0 || selectedItem.isClosed ? true : false"
            :transaction-type="transactionType"
            @validate-balance="() => validate(invoiceValidationTypes.balance)"
          />
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row v-show="selectedItem.isPosted === false">
        <b-col
          cols="12"
          class="d-flex justify-content-end sticky-bottom"
        >
          <b-button
            v-if="id || currentBranch.isMaster"
            class="mx-1"
            variant="outline-primary"
            :disabled="selectedItem.lines.length <= 0"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t("print") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            :disabled="
              selectedItem.isComplete ||
                (!selectedItem.sourceTransactionId && selectedItem.isPosted) ||
                !selectedItem.lines.length ||
                valid === false
            "
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t("saveAndPrint") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            class="ml-1"
            variant="relief-primary"
            data-action-type="save"
            :disabled="
              selectedItem.isComplete ||
                (!selectedItem.sourceTransactionId && selectedItem.isPosted) ||
                !selectedItem.lines.length ||
                valid === false
            "
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
          <b-button
            class="ml-1"
            variant="outline-primary"
            @click="initObj()"
          >
            <feather-icon
              icon="RotateCwIcon"
              class="mr-50"
            />
            {{ $t("addNew") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { exchangePurchMethods } from '@/libs/acl/Lookups';
import invoiceMixin from '@/mixin/purchaseMixin';
import reportMixin from '@/mixin/reportMixin';
import items from './components/items.vue';

export default {
  components: {
    items,
  },
  mixins: [invoiceMixin, reportMixin],
  props: ['id'],
  data() {
    return {
      exchangeDest: [],
      changedData: [],
      exchangeDestList: [],
      methodTypeName: '',
      exchangeMethods: exchangePurchMethods,
      transactionType: 'additionOrder',
      payemntType: 'Safes',
      paymentType: '',
      paymentMethod: {
        value: null,
        id: null,
        arabicName: '',
        englishName: '',
      },
      customer: {},
      payment: {
        transactionId: 0,
        lineSerial: 0,
        value: 0,
        paymentMethod: 'safe',
        safeId: 0,
        otherPaymentMethodId: 0,
        referenceNumber: 'string',
      },
      items: [],
      errors: [],
      isValid: true,
      sourceId: null,
      checkIsOpening: false,
      suppliers: []
    };
  },
  computed: {
    totalExpenses() {
      return this.selectedItem.expenses.reduce((sum, item) => {
        sum += item.isTaxApplied === false ? parseFloat(item.value) : parseFloat(item.netValue);
        return sum;
      }, 0);
    },
    total() {
      return this.selectedItem.lines.reduce((sum, item) => {
        sum += item.oldNet;
        return sum;
      }, 0);
    },
    valid() {
      this.selectedItem.lines.forEach((res) => {
        if (this.selectedItem.sourceTransactionId && res.originalQuantity && (res.originalQuantity - res.consumedQuantity || 0) < res.quantity) {
          res.isValid = false;
        } else if (this.selectedItem.sourceTransactionId && res.originalQuantity && (res.originalQuantity - res.consumedQuantity || 0) >= res.quantity) {
          res.isValid = true
        }
      })
      for (let i = 0; i < this.selectedItem.lines.length; i++) {
        const element = this.selectedItem.lines[i];
        if (element.isValid === false) {
          this.doneAlert({ text: this.$t('originalQuantityIsLessThanQty'), type: 'error' });
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = false;
          break;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = true
        }
      }
      return this.isValid;
    }
  },
  watch: {
    'selectedItem.exchangeDestinationType'(newVal) {
      switch (newVal) {
        case 'suppliers':
          this.changedData = this.lookups.suppliers;
          break;
        case 'agents':
          this.changedData = this.lookups.agents
          break;
        case 'exchangeDestinations':
          this.changedData = this.exchangeDestList;
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    this.updateViewModel();
  },
  mounted() {
    this.sourceId = this.selectedItem.sourceTransactionId;
    this.selectedItem.transactionDate = this.getDate();
    this.selectedItem.transactionTime = this.getTime()
    // this.updateViewModel();
    this.getExchangeDestinations();
    this.get({
      url: `Aggregates/purchaseViewModel?userId=${this.profile.userId}&isClosed=false&transactionType=purch&dealerType=`,
    }).then((data) => {
      this.lookups = data;
    }).then(() => {
      const id = this.id || this.selectedItem.sourceTransactionId;
      if (id > 0) {
        let transactionType = this.$route.query.sourceTransactionType;
        if (this.id) transactionType = this.transactionType;

        this.get({ url: `ItemTransactions/${transactionType}/${id}` }).then(
          (data) => {
            this.selectedItem = data;
            this.selectedItem.supplierId = data.dealerId
            this.selectedItem.lines.forEach((item) => {
              item.originalQuantity = item.quantity;
              item.consumedQuantity = item.consumedQuantity === null ? 0 : item.consumedQuantity;
              item.units = [
                {
                  unitId: item.unitId,
                  unitArabicName: item.unitArabicName,
                  unitEnglishName: item.unitEnglishName,
                  price: (this.selectedItem.sourceTransactionId) ? item.price : item.salesPrice,
                  minSalesPrice: item.minSalesPrice,
                  maxSalesPrice: item.maxSalesPrice,
                  barcode: item.mainUnitBarcode,
                  isMain: true,

                },
              ];
              item.oldTotal = item.total;
              item.oldNet = item.net;
              item.costPrice = (this.selectedItem.sourceTransactionId) ? item.price : item.costPrice;
            });
            this.selectedItem.exchangeDestinationType = data.exchangeDestinationType;
            this.changedTypes(data.exchangeDestinationType)
            const exhangeDestObj = this.exchangeMethods.find(
              (val) => val.id === this.selectedItem.exchangeDestinationType
            ) || {
              arabicName: ''
            }
            this.methodTypeName = this.isRight ? exhangeDestObj.arabicName : exhangeDestObj.englishName;
            if (this.sourceId > 0) this.prepareItemfromSource();
          }
        );
      }
      if (this.selectedItem.storeId) {
        this.validateOpening(this.selectedItem.storeId);
      }
    })
  },
  methods: {
    validateOpening(storeId) {
      this.get({ url: 'ItemTransactions/opening-addition-order-transaction', id: storeId }).then((data) => {
        this.checkIsOpening = data;
      })
    },
    changedTypes(newVal) {
      switch (newVal) {
        case 'suppliers':
          this.changedData = this.lookups.suppliers;
          break;
        case 'agents':
          this.changedData = this.lookups.agents
          break;
        case 'exchangeDestinations':
          this.changedData = this.exchangeDestList;
          break;
        default:
          break;
      }
    },
    getExchangeDestinations() {
      // this.get({ url: 'ExchangeDestinations' }).then((data) => {
      //   this.exchangeDestList = data;
      // });
      this.get({ url: 'suppliers' }).then((data) => {
        this.suppliers = data;
      });
    },
    initObj() {
      this.getSelecteItemObj();
      this.selectedItem.transactionType = this.transactionType;
    },
    prepareItemfromSource() {
      this.selectedItem.id = 0;
      if (this.sourceId) {
        this.selectedItem.isPosted = false;
        this.selectedItem.postedVoucherId = null;
        this.selectedItem.voucherCode = null;
      }
      this.selectedItem.transactionType = this.transactionType;
      this.selectedItem.lines = this.selectedItem.lines.map((item) => {
        item.oldTotal = item.total;
        item.oldNet = item.net;
        if (this.selectedItem.expenses.length > 0) {
          // get total costs for item like in invoice
          item.totalCosts = this.fraction((item.oldTotal / this.total) * this.totalExpenses);
          // recalc costprice for item with new costs
          item.costPrice = this.fraction((item.totalCosts / item.quantity) + item.price);
          item.total = this.fraction(item.costPrice * item.quantity);
          item.net = item.total;
        } else {
          item.costPrice = (this.selectedItem.sourceTransactionId) ? item.price : item.costPrice;
        }
        return {
          ...item,
          consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
          originalQuantity: item.quantity,
          quantity: item.quantity - (item.consumedQuantity || 0),
          costPrice: this.selectedItem.expenses.length > 0 ? item.costPrice : item.price,
        };
      });
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.transactionTime = this.time;
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.code = null;
      this.selectedItem.referenceNumber = null;
      this.selectedItem.sourceTransactionId = this.sourceId;
      this.selectedItem.taxes = [];
      this.selectedItem.total = this.selectedItem.netBeforeTaxes;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.selectedItem.payments = [];
      this.selectedItem.exchangeDestinationType = 'suppliers'
      // this.selectedItem.expenses = [];
    },
    changeCustomer(v) {
      if (this.isEditable) {
        this.selectedItem.agentId = v.agentId || null;
        this.selectedItem.onTheFlyDealerName = '';
      }
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
      this.customer = v;
    },
    validate(validationType) {
      let url = '';
      let type = '';
      if (validationType === this.invoiceValidationTypes.balance) {
        url = 'ItemTransactions/validate/item-balance';
        type = this.currentBranch.negativeItemBalancePolicy;
      } else {
        url = 'ItemTransactions/validate/sales-price';
        type = this.currentBranch.minSalesPricePolicy;
      }
      if (type === 'none') return;
      this.errors = this.errors.filter((item) => item.errorType !== type);
      this.create({
        url,
        data: this.selectedItem,
      });
    },
    validateBalance() {
      this.create({
        url: 'ItemTransactions/validate/item-balance',
        data: this.selectedItem,
      })
        .then(() => { })
        .catch(({ data }) => {
          this.errors.push({
            text: data.detail,
            type: 'error',
            errorType: 'balance',
          });
          this.doneAlert({ text: data.detail, type: 'error' });
        });
    },
    // beforeSaveValidation() {
    //   this.valid = true;
    //   this.validTwo = false;
    //   this.selectedItem.lines.forEach((res) => {
    //     if (res.originalQuantity && res.consumedQuantity && (res.originalQuantity - res.consumedQuantity || 0) < res.quantity) {
    //     // alert here
    //     this.doneAlert({ text: this.$t('originalQuantityIsLessThanQty'), type: 'error' });
    //     this.valid = false;
    //   } else {
    //     this.validTwo = true;
    //   }
    //   })
    // },
    beforeSaveValidationDestination() {
      if (!this.selectedItem.sourceTransactionId) {
        if (this.selectedItem.exchangeDestinationType && !this.selectedItem.selectedDestinationId) {
          // alert here
          this.doneAlert({ text: this.$t('youShouldSelectSelectedDestination'), type: 'error' });
          return false;
        }
        if (!this.selectedItem.exchangeDestinationType && !this.selectedItem.selectedDestinationId) {
          // alert here
          this.doneAlert({ text: this.$t('youShouldSelectSelectedDestination'), type: 'error' });
          return false;
        }
      }
      return true;
    },
    save(type) {
      this.selectedItem.expenses = [];
      this.selectedItem.netBeforeTaxes = this.selectedItem.lines.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.selectedItem.total = this.selectedItem.netBeforeTaxes;
      // if (!this.beforeSaveValidationDestination()) return;
      if (this.selectedItem.lines.find(a => a.quantity <= 0)) {
        this.doneAlert({
          text: this.$t('youAreNotAllowedToAddQtyEqualZero'),
          type: 'error'
        });
        return;
      }
      // this.beforeSaveValidation();
      // if (!this.valid && this.validTwo) return;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return;
      }

      // fill data
      this.selectedItem.dealerType = 'supplier'
      this.selectedItem.dealerId = this.selectedItem.supplierId
      this.selectedItem.paymentType = 'credit';

      // then save
      (this.id > 0
        ? this.update({
          url: `ItemTransactions/${this.transactionType}`,
          id: this.id,
          data: this.getModel(),
        }).then(() => {
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          const id = this.id;
          if (type === 'saveAndPrint') this.print(id);
          this.selectedItem.transactionType = this.transactionType;
          if (this.selectedItem.id > 0 || this.$route.query.sourceTransactionType) this.$router.push({ name: 'orders' });
          return this.getSelecteItemObj();
        }).then(() => {
          if (this.selectedItem.storeId) {
            this.validateOpening(this.selectedItem.storeId);
          }
        })
        : this.create({
          url: `ItemTransactions/${this.transactionType}`,
          data: this.getModel(),
        }).then((dataId) => {
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          if (type === 'saveAndPrint') this.print(dataId);
          this.selectedItem.transactionType = this.transactionType;
          if (this.selectedItem.id > 0 || this.$route.query.sourceTransactionType) this.$router.push({ name: 'orders' });
          return this.getSelecteItemObj();
        }).then(() => {
          if (this.selectedItem.storeId) {
            this.validateOpening(this.selectedItem.storeId);
          }
        })
      )
    },
    print(id) {
      const printedItem = {
        id: id
      }
      this.printReport('AdditionOrder-ar', printedItem);
    },
  },
};
</script>
